import React from 'react';
import { useContext } from 'react';
import '../css/main.css'; 
import SidebarItem from './SidebarItem';
import SidebarContext from '../context/SidebarContext';


const Sidebar = () => {
    const { collapsed, toggleCollapsed } = useContext(SidebarContext);

    const menuItems = [
        { icon: 'fa-home', text: 'HOME', to: '/' },
        { icon: 'fa-envelope', text: 'ALL ORDERS', to: '/orders' },
        { icon: 'fas fa-shopping-cart', text: 'TO BUY', to: '/to_buy' },
        { icon: 'fa-exclamation-triangle', text: 'TO FIND', to: '/to_find' },
        { icon: 'fas fa-plane-departure', text: 'TO SHIP', to: '/to_ship' },
        { icon: 'fas fa-print', text: 'TO PRINT', to: '/to_print' },
        { icon: 'fas fa-truck', text: 'SHIPMENTS', to: '/shipments' },
        { icon: 'fas fa-cube', text: 'SHIPPING', to: '/shipping' },
        { icon: 'fas fa-undo', text: 'RETURNS', to: '/returns' },
        { icon: 'fas fa-window-close', text: 'CANCEL-REFUNDS', to: '/cancel_refunds' },
        { icon: 'fas fa-book', text: 'PRODUCTS', to: '/products' },
        { icon: 'fas fa-road', text: 'ROUTES', to: '/routes' },
        { icon: 'fas fa-industry', text: 'SUPPLIERS', to: '/suppliers' },
        { icon: 'fas fa-wrench', text: 'ACTIONS', to: '/actions' },
        { icon: 'fas fa-headset', text:'QUALITY', to: '/quality' },
        { icon: 'fas fa-money-bill-wave', text: 'FINANCE', to: '/finance' },
        { icon: 'fas fa-warehouse', text: 'INVENTORY', to: '/inventory' },
        { icon: 'fas fa-user-secret', text: 'COMPETITORS', to: '/competitors' },
        { icon: 'fas fa-chart-line', text: 'METRICS', to: '/metrics' },
        { icon: 'fas fa-code', text: 'SCRIPTS', to: '/scripts' },
      ];

    return (
        <div className={`sidebar ${collapsed ? 'collapsed' : ''}`}>
        <i className="fas fa-bars sidebar-btn" onClick={toggleCollapsed}></i>
        <div className={`sidebar-content ${collapsed ? 'collapsed' : ''}`}>
        {menuItems.map((item) => (
          <SidebarItem
            key={item.text}
            icon={item.icon}
            text={item.text}
            to={item.to}
          />
        ))}
        </div>
        <div className="sidebar-bottom">
        </div>
        </div>
    );
};

export default Sidebar;
