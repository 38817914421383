import { Link } from 'react-router-dom';

const ShipmentLine = ({
  shipment_id,
  carrier,
  tracking_number,
  shipment_status,
  shipment_date,
}) => {

  
return (
  <div className="table-row">
              <div className="date">{shipment_date}</div>
              <div className="small">{tracking_number}</div>
              <div className="large">{carrier}</div>
              <div className="small">{shipment_status}</div>
              <div className="action"><Link to={`/shipment/${shipment_id}`}><i className="fas fa-eye"></i></Link></div>
              
  </div>
);
};

export default ShipmentLine;
