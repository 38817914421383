import '../css/orders.css';
import useFetch from "../api/useFetch";
import usePost from '../api/usePost';
import OrderLine from './OrderLine'
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Loading from './Loading';

function Shipment({notify}) {
  const { id } = useParams();
    const [refresh, setRefresh] = useState(false);
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [showReceived, setShowReceived] = useState(false);
        const { postData } = usePost();
  
    const { result: orders, isLoading: ordersLoading, error: ordersError } = useFetch(`/shipment_orders/${id}`,[refresh]);
    
    
  
  const selectAll = (orders) => {
    const orderIds = orders.map((order) => order.id);
    setSelectedOrders([...selectedOrders, ...orderIds]);
  }
  
  const handleRefresh = () => {
    setRefresh(!refresh)
  }

  const markReceived = async () =>  {
  const response = await postData('/mark_received_multi', {order_ids: selectedOrders});
  if (response) {
    setRefresh(!refresh)
    setSelectedOrders([])
    notify('Orders Mark as Received')
  }
};

const hideReceived =  () => {
  setShowReceived(!showReceived)
}

  return (
    <>
      <div div className="orders">
        <div div className="title">
          ORDERS
          <i className="fas fa-sync" onClick={handleRefresh}></i>
        </div>
        
        <div className='orders-select'>
        {orders &&  (<div className='group-selected-button' onClick={hideReceived}>{showReceived ? "Show Received" : "Hide Received"}</div>)}
        {orders &&  (<div className='group-selected-button' onClick={() => selectAll(orders)}>Select All</div>)}
        {selectedOrders.length > 0 && (<div className='group-selected-button' onClick={markReceived}>Mark as Received</div>)}
        {selectedOrders.length > 0 && (<div className='selected-orders'>{selectedOrders.length} order(s) selected   <i class="fas fa-times" onClick={() => setSelectedOrders([])}></i></div>)}
        </div>
        <div className="table">
          <div className="table-header">
            <div className="domain">MarketPlace</div>
            <div className="date">Order Date</div>
            <div className="order_id">Order Id</div>
            <div className="product">Product</div>
            <div className="sourcing">Sourcing</div>
            <div className="destination">Destination</div>
            <div className="price">Price</div>
            <div className="status">Status</div>
            <div className="status">Ext Status</div>
            <div className="action">Details</div>
            <div className="action">Select</div>
          </div>
          <div className="table-body">
            {ordersLoading && <div div className='table-placeholder'><Loading /></div>}
            {ordersError && <div>{ordersError}</div>}
            {!ordersLoading &&
              orders &&
              orders.filter(order => showReceived ? order.is_received === false : true).map((order) => (
                <OrderLine
                  has_checkbox = {true}
                  key={order.id}
                  id={order.id}
                  marketplace_domain={order.marketplace && order.marketplace.domain}
                  marketplace_api_id={order.marketplace && order.marketplace.api_id}
                  order_date={order.purchase_date}
                  order_id={order.marketplace_order_id}
                  product_name={
                    order.is_multiproducts 
                      ? "Multiproducts"
                      : 
                       order.products.length === 0 ?
                       "Unknown"
                       :
                      order.products[0].product.name.length > 50
                      ? order.products[0].product.name.substring(0, 45).concat(" (...)")
                      : order.products[0].product.name
                  }
                  product_id={
                    (order.is_multiproducts || order.products.length === 0)
                      ? null
                      : order.products[0].product.id
                  }
                  product_sourcing_marketplace={
                    order.sourcing_marketplace ? order.sourcing_marketplace.name : "Unknown"
                  }
                  detination_country={order.destination_country}
                  net_revenue={order.net_revenue_usd}
                  internal_status={order.internal_status}
                  marketplace_status={order.marketplace_status}
                  selectedOrders={selectedOrders}
                  setSelectedOrders={setSelectedOrders}
                  feedback={false}
                />
              ))}
          </div>
        </div>
      </div>
      

    </>
  );  
}

export default Shipment