import { useState } from 'react';
import DashboardItemMetric from './DashboardItemMetric'
import DashboardItemBarChart from './DashboardItemBarChart'
import useFetch from '../api/useFetch'
import '../css/dashboard.css';
import { Link } from 'react-router-dom'
import OrderLine from './OrderLine'
import Loading from './Loading';

const Dashboard = () => {

const [refresh, setRefresh] = useState(false);
const { result: metrics, isLoading: metricsLoading, error: metricsError } = useFetch('/metrics');
const { result: orders, isLoading: ordersLoading, error: ordersError } = useFetch('/otdr_orders',refresh);
const { result: data, isLoading: dataLoading, error: dataError } = useFetch('/net_revenue_by_day');

const handleRefresh = () => {
    setRefresh(!refresh)
}
  return (
    <>
    <div className="metrics">
        {metricsLoading && <div><Loading /> </div>}
        {metricsError && <div>{metricsError}</div>}
        {metrics && Object.entries(metrics).map(([key, value]) => (
        <DashboardItemMetric data={key} value={value} time_choice={true} text={null}/>
))}
    </div>
    <div className="graphs">
      {dataLoading && <div><Loading /> </div>}
      {dataError && <div>{dataError}</div>}
      {data && <>
        <DashboardItemBarChart chartData={data}/>
        <DashboardItemBarChart chartData={data}/>
        <DashboardItemBarChart chartData={data}/>
        </>
        }
    </div>
    <div className="orders">
      <div className="title">
        CURRENT OTDR RISK ORDERS
        <Link to="/orders">
          <i className="fas fa-th-list"></i>
        </Link>
        <i className="fas fa-sync" onClick={handleRefresh}></i>
      </div>
      <div className="table">
        <div className="table-header">
          <div className="marketplace">
            MarketPlace
          </div>
          <div className="domain">
            Domain
          </div>
          <div className="date">
            Order Date
          </div>
          <div className="order_id">
            Order Id
          </div>
          <div className="product">
            Product
          </div>
          <div className="sourcing">
            Sourcing
          </div>
          <div className="destination">
            Destination
          </div>
          <div className="price">
            Price
          </div>
          <div className="status">
            Status
          </div>
          <div className="action">Details</div>
        </div>
        <div className="table-body">
        {ordersLoading && <div><Loading /></div>}
        {ordersError && <div>{ordersError}</div>}
        {!ordersLoading && orders &&  orders.map((order) => (
           <OrderLine 
           key={order.id}
           id={order.id}
           marketplace_name={order.marketplace.name}
           marketplace_domain={order.marketplace.domain}
           order_date={order.purchase_date}
           order_id={order.marketplace_order_id}
           product_name={
            order.is_multiproducts
              ? "Multiproducts"
              : order.products[0].product.name.length > 50
              ? order.products[0].product.name.substring(0, 45).concat(" (...)")
              : order.products[0].product.name
          }
          product_id={
            order.is_multiproducts
              ? null
              : order.products[0].product.id
          }
           product_sourcing_marketplace={order.is_multiproducts ? 'Multiproducts' : order.products[0].product.sourcing_marketplace.name}
           detination_country={order.destination_country}
           net_revenue={order.net_revenue}
           internal_status={order.internal_status}
           />
          ))}
        </div>
    </div>
    </div>
    </>
  )
}

export default Dashboard